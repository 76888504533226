import React, { useState } from "react";

const TimePicker = ({
  timePickerOpen,
  setTimePickerOpen,
  selectedTime = [],
  setSelectedTime
}) => {
  const timeSlots = [
    "00:00 - 01:00",
    "01:00 - 02:00",
    "02:00 - 03:00",
    "11:00 - 12:00",
    "12:00 - 13:00",
    "13:00 - 14:00",
    "14:00 - 15:00",
    "15:00 - 16:00",
    "16:00 - 17:00",
    "17:00 - 18:00",
    "18:00 - 19:00",
    "19:00 - 20:00",
    "21:00 - 22:00",
    "22:00 - 23:00",
    "23:00 - 00:00"
  ];

  const [tempSelectedTime, setTempSelectedTime] = useState(selectedTime); // Temporary state for managing selected time
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllToggle = () => {
    if (selectAll) {
      setTempSelectedTime([]); // Update temporary state
    } else {
      setTempSelectedTime(timeSlots); // Update temporary state
    }
    setSelectAll(!selectAll);
  };

  const handleTimeSlotClick = (timeSlot) => {
    setTempSelectedTime((prevSlots) =>
      prevSlots.includes(timeSlot)
        ? prevSlots.filter((slot) => slot !== timeSlot)
        : [...prevSlots, timeSlot]
    );
  };

  const handleApplyClick = () => {
    setSelectedTime(tempSelectedTime); // Update the main selectedTime state when clicking "Terapkan"
    setTimePickerOpen(false);
  };

  return (
    <div className="relative">
      {timePickerOpen && (
        <div className="absolute -right-10 sm:right-0 top-5 sm:top-full mt-2 bg-white p-5 rounded-lg shadow-lg z-10 w-[280px] sm:w-[380px]">
          <div className="flex w-full items-center justify-between text-12">
            <p>Pilih Semua Waktu</p>
            <SwitchButton
              isOn={selectAll}
              handleToggle={handleSelectAllToggle}
            />
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 mt-8">
            {timeSlots.map((timeSlot, index) => (
              <button
                key={index}
                className={`p-2 rounded-xl transition-all ease-in-out duration-300 flex justify-center items-center text-primaryBlack text-12 font-semibold ${
                  tempSelectedTime.includes(timeSlot)
                    ? "bg-accentSoftOrange2 border-2 border-primaryOrange"
                    : "hover:bg-primaryOrange hover:text-white hover:bg-opacity-85"
                }`}
                onClick={() => handleTimeSlotClick(timeSlot)}
              >
                {timeSlot}
              </button>
            ))}
          </div>
          <button
            onClick={handleApplyClick}
            className="mt-8 bg-primaryOrange text-white py-2 px-4 rounded-lg w-full"
          >
            Terapkan
          </button>
        </div>
      )}
    </div>
  );
};

const SwitchButton = ({ isOn, handleToggle }) => {
  return (
    <div
      className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${
        isOn ? "bg-primaryOrange" : "bg-gray-300"
      }`}
      onClick={handleToggle}
    >
      <div
        className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform ${
          isOn ? "translate-x-6" : "translate-x-0"
        }`}
      />
    </div>
  );
};

export default TimePicker;
