import React from "react";

const NotFound = () => {
  return (
    <div className="flex w-full h-screen items-center justify-center text-center">
      404 page not found
    </div>
  );
};

export default NotFound;
